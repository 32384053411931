.manage-outlet {
    padding: 24px;
    background: #ffffff;
    border-width: 1px 1px 1px 1px;
    border-style: solid;
    border-color: #e6f1f6;
    border-radius: 0px;
}

.search-ppd {
    border: 0px solid !important;
    border-bottom: 1.5px solid #66adcb !important;
    border-radius: 0;
    font-weight: 500;
    padding: 5px 20px 5px 0;
    width: 80%;
}

.search-ppd:focus {
    box-shadow: none !important;
}

.search-ppd-label {
    color: #004765;
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: 20px;
    letter-spacing: 0.28px;
    margin-bottom: 0;
}

.manage-ppd-table {
    background: #F5F5F5;
    border-radius: 10px 10px 0px 0px;

    table {
        thead {
            tr {

                th {
                    background: #e6f1f6;
                    font-weight: 600;
                    border: 0px solid #E5E5E5;
                    border-bottom: 1px solid #E5E5E5;
                    padding: 12px 15px;
                    color: #005e86;
                    font-size: 14px;
                    letter-spacing: 0.02em;

                    &:first-child {
                        border-left: 0;
                    }

                    &:last-child {
                        border-right: 0;
                    }
                    .btn_select_all_location {
                        background: #e6f1f6;
                        font-weight: 600;
                        border: 0px solid #E5E5E5;
                        color: #005e86;
                        font-size: 14px;
                        letter-spacing: 0.02em;
                        padding-left: 20%;
                    }

                    .provision_count {
                        background-color: #ffffff;
                        margin-left: 10px;
                        display: inline-block;
                        padding: 5px 10px;
                        border-radius: 20px;
                    }
                    .btn_select_all_provision {
                        background: #e6f1f6;
                        font-weight: 600;
                        border: 0px solid #E5E5E5;
                        color: #005e86;
                        font-size: 14px;
                        letter-spacing: 0.02em;
                        padding-left: 10px;
                    }
                }
            }
        }
    }

    tbody {
        tr {

            td {
                padding: 10px 10px 0px 10px;
                font-size: 14px;
                vertical-align: middle;
                letter-spacing: 0.02em;
                color: #404040;
                background-color: transparent;
                border: 0px solid #E5E5E5;

                a {
                    text-decoration: none;
                    color: inherit;
                }
            }
        }
    }
}

.manage-sqcn-button {
    background: #0076a8;
    border-radius: 20px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    border: 1px solid #0076a8;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff;
    margin-right: 10px;
    position: relative;
    top: 1px;
}

.manage-ppd-button {
    background: #0076a8;
    border-radius: 20px;
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
    border: 1px solid #0076a8;
    color: #fff;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #ffffff;
    position: relative;
    top: 1px;
}

.supergroup_check div {
    display: inline-block;
    margin-right: 10px;
}

.selected_location {
    border: 0px solid transparent !important;
    background-color: transparent;
    text-align: left;

    .locationname_text {
        color: #404040;
        font-size: 14px;
        font-style: normal;
        font-weight: 700;
        line-height: 20px;
        letter-spacing: 0.28px;
    }

    .address_text {
        color: #404040;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.28px;
    }
}

.unselectedClass {
    padding: 3px 6px;
    align-items: flex-start;
    border-radius: 10px;
    background: #fff;
}

.selectedClass {
    border: 1px solid #0076A8 !important;
    padding: 3px 6px;
    align-items: flex-start;
    border-radius: 10px;
    background: #fff;
}

.move_button {
    background-color: transparent;
    border: 0px solid transparent;
    position: relative;
    top: 140px;
}

.move_button.left_right {
    position: relative;
    left: 4px;
}


.location_data_list {
    max-height: 455px;
    height: 455px;
    overflow-y: auto;
    display: table-caption;
}

/* location list scrollbar  */
.location_data_list::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.location_data_list::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

/* Handle */
.location_data_list::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
    height: 50px;
}

/* Handle on hover */
.location_data_list::-webkit-scrollbar-thumb:hover {
    background: #d9d9d9;
}

.location_data_list1 {
    max-height: 503px;
    height: 503px;
    overflow-y: auto;
    display: table-caption;
}

/* location list scrollbar  */
.location_data_list1::-webkit-scrollbar {
    width: 6px;
}

/* Track */
.location_data_list1::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px transparent;
    border-radius: 10px;
}

/* Handle */
.location_data_list1::-webkit-scrollbar-thumb {
    background: #d9d9d9;
    border-radius: 10px;
    height: 50px;
}

/* Handle on hover */
.location_data_list1::-webkit-scrollbar-thumb:hover {
    background: #d9d9d9;
}



.search_column.ppd {
    padding: 10px 10px 0px 10px !important;
    background-color: transparent !important;
    border: 0px solid #E5E5E5;
}

.error_ppd {
    color: red;
    font-size: 12px;
}

.close_ppd_box {
    padding: 15px 0px 15px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    background: #E6F1F6;
    margin-top: 50px;
    text-align: center;

    p {
        color: #000;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: 0.28px;
    }
}

.white-btn-ppd {
    padding: 10px 30px;
    border-radius: 24px;
    border: 2px solid #E5E5E5;
    background: #FFF;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.32px;
    color: #404040;
    margin-right: 5px;
}

.blue-btn-ppd {
    padding: 10px 30px;
    border-radius: 24px;
    border: 2px solid #0076A8;
    background: #0076A8;
    font-size: 16px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    letter-spacing: 0.32px;
    color: #fff;
}

.table>:not(caption)>*>* {
    border-bottom-width: 0px !important;
    box-shadow: none !important;
}

.search-input_ppd {
    background: url("./images/search.svg") #fff no-repeat 5px center !important;
    border: 1px solid #fff !important;
    padding: 6px 0px 8px 28px !important;
    border-radius: 10 !important;
    font-size: 14px !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 20px !important;
    letter-spacing: 0.28px !important;
    color: #171717 !important;
    width: 98% !important;
  }
  .search-input_ppd:focus {
    box-shadow: none !important;
}

.search-npi.loc {
 width:86%;
 display: inline-block;
}

.sort-icon_class {
 background-color: #fff !important;
 height: 35px;
 border-radius: 5px;
 position: relative;
 top:1px;
 border: 0 !important;
}

:focus-visible {
    outline: -webkit-focus-ring-color auto 0px !important;
}

.practice-search-dashboard {
    padding: 0px 20px;
  }
  
  .dashboard-search {
    background-image: url("../../../../../images/blue_search.svg") !important;
    background-position: right center !important;
    background-repeat: no-repeat !important;
    padding: 6px 0px 8px 0px !important;
    line-height: 20px !important;
    letter-spacing: 0.28px !important;
    color: #171717 !important;
    width: 98% !important;
  }
  
  
  
  