#root {
  background: #f5f5f5;
}

.nav li div {
  padding: 16px 24px;
  cursor: pointer;
  border: 1px solid #e4e4e4;
  border-bottom: none;
  margin-right: 8px;
  border-radius: 20px 20px 0px 0px;
  background: #fafafa;
  font-weight: 600;
}

.nav li div:before {
  content: "";
  width: 16px;
  height: 16px;
  display: inline-block;
  margin-right: 8px;
  vertical-align: sub;
}

.nav li div#tab1:before {
  background: url(practice-info-black-icon.svg) no-repeat;
}
.nav li div.active#tab1:before {
  background: url(practice-info-blue-icon.svg) no-repeat;
}
.nav li div#tab2:before {
  background: url(contact-info-black-icon.svg) no-repeat;
}
.nav li div.active#tab2:before {
  background: url(contact-info-blue-icon.svg) no-repeat;
}

.nav li div#tab3:before {
  background: url(provider-list-black-icon.svg) no-repeat;
}

.nav li div.active#tab3:before {
  background: url(provider-list-blue-icon.svg) no-repeat;
}

.nav li div.active {
  color: #005e86;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.1);
  background: #ffffff;
}

.outlet {
  padding: 24px;
  background: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #e6f1f6;
  border-radius: 0px 20px 20px 20px;
}

@media only screen and (max-width: 768px) {
  .nav {
    display: block !important;
  }
  .nav li div {
    border-radius: 0;
    margin-right: 0;
  }
  .outlet {
    border-radius: 0px 0px 20px 20px;
  }
}
