.main-block {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 20px;
  background: #fafafa;
  border-radius: 16px;
  margin-bottom: 20px;
}

.not-flex {
  display: block !important;
}

.flex {
  display: flex !important;
}

.upper-space {
  padding-top: 2rem;
}

.padding-top8 {
  padding-top: 8px !important;
}

.padding-top4 {
  padding-top: 4px !important;
}

.block {
  flex-grow: 1;
  flex-basis: 0;
  padding-right: 15px;
}


.info-text {
  color: #92400e;
  letter-spacing: 0.02em;
  font-weight: 500;
  font-size: 14px;
}

.info-text img {
  margin-right: 11px;
}

.info-text a {
  color: #0076a8;
}

.main-block .block .title {
  color: #004765;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
}

.main-block .block .form-label {
  color: #004765;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}

.main-block .block .info {
  font-weight: 500;
  letter-spacing: 0.02em;
  color: #171717;
}

.view-provider {
  background: #ffffff;
  border: 1px solid #e1eff5;
  padding: 24px;
  border-radius: 20px;
}

.provider-profile {
  width: 128px;
  height: 128px;
  box-shadow: 0px 10px 15px -3px rgba(16, 24, 40, 0.1),
    0px 4px 6px -4px rgba(16, 24, 40, 0.1);
  border: 4px solid #ffffff;
  border-radius: 100px;
  background: #e6f1f6;
  text-align: center;
  margin: 0 auto;
}

.firstletter {
  position: relative;
  width: 128px;
  height: 56px;
  left: calc(50% - 128px / 2);
  top: calc(55% - 56px / 2 - 4px);
  font-weight: 500;
  font-size: 52px;
  line-height: 56px;
  letter-spacing: 0.02em;
  color: #0076a8;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.manage-button {
  background: #0076A8;
  border: none;
  border-radius: 20px;
  padding: 10px 16px;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: justify;
  letter-spacing: 0.02em;
  color: #fff;
}

@media only screen and (max-width: 768px) {
  .main-block {
    display: block;
  }

  .block {
    margin-bottom: 8px;
  }
}

.still-at-locations {
  min-width: 153px;
}

.delete-button {
  border: none;
  background: transparent;
}

.validate_btn {
  width: 12% !important;
}

.margin_bottom1 {
  margin-bottom: 0.5rem !important;
}

.margin_bottom2 {
  margin-bottom: 0.25rem !important;
}

.margin_bottom3 {
  margin-bottom: 0.5rem !important;
  color: #DC2626 !important;
}

.btn_close_padding {
  .btn-close {
    padding-left: 30px !important;
  }
}

.phone-head {
  width: 17%;
}

.phone-head1 {
  width: 12%;
}

.phone-head2 {
  width: 15%;
}

.practice-location {
  background: #FAFAFA;
  border-radius: 20px;
  padding: 20px;
  margin-bottom: 15px;
}

input.nb-input.location {
  border: none;
  border-bottom: 1.5px solid #66adcb;
  border-radius: 0;
  font-weight: 500;
  padding: 5px 20px 5px 0;
  width: 50%;
}

.practice-location .location-label {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #004765 !important;
  margin-bottom: 2px;
}

.location-labelText {
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #004765 !important;
  margin-bottom: 2px;
}

.delete-action {
  text-align: center;
}

.alert-warning {
  --bs-alert-color: #92400E !important;
  --bs-alert-bg: #FFFBEB !important;
  --bs-alert-border-color: #ffecb5 !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  line-height: 24px !important;
}

.close-icon-button1 {
  float: right;
  border: 0 !important;
  background: transparent !important;
  padding: 0 !important;
  position: relative;
  right: -3px;
}

.action-status {
  vertical-align: middle;
}

.nb-input.street::placeholder {
  color:#a3a3a3 !important;
  font-size: 14px;
  letter-spacing: 0.02em;
}

.display-inline {
  padding: 20px;
  background: #fafafa;
  border-radius: 16px;
  margin-bottom: 20px;
}
.center-align-flex {
  align-items: center;
}
.create_provider .form-label{
  min-height: 39px;
}
.create_provider .block , .create_provider_select .block{
  text-align: left;
}
.block .details-label{
  text-align: left;
}
.block .form-label{
  text-align: left;
}

.create_provider_select .form-select-sm{
  border-radius: 0px !important;
  padding: 4px 1px !important;
  font-weight: 500;
  font-size: 1rem;
  color: #212529;
}
.create_provider_select .form-select.is-invalid, .create_provider_select .was-validated .create_provider_select .form-select:invalid{
  border-bottom:1px solid red !important
}
.create_provider_select .pos-relative{
  position: relative;
  top: -5px;
}
.edit-provider .location-banner .practice-loc-title{
  text-align: left;
}

select.nb-select {
  border: none;
  border-bottom: 1px solid #a3a3a3;
  border-radius: 0;
  background: #fafafa;
  padding: 0;
  padding-top: 1px;
  position: relative;
  background-image: url(./dropdownIcon.svg);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  font-weight: 500;
  &:focus {
    box-shadow: none;
    border-color: #66adcb;
  }
}