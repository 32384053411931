.header {
  background-color: #fff;
  height: 98px;
}

button.user-signout {
  border: 0;
  background: #fff;
  position: relative;
  top: -3px;
}

.content {
  height: 64px;
  position: relative;
  top: 16px;
}

._logo {
  position: relative;
  top: 7px;
  left: 4px;
  max-width: 212px;
}

.logo-text {
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #0076a8;
  letter-spacing: 0.02em;
  top: 10px;
  position: relative;
  left: 50px;
  display: inline-block;
}

.user-name {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}

.user-signout {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #0076a8;
  letter-spacing: 0.02em;
  cursor: pointer;
}

.role-name {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #000;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}

.user-content {
  display: inline-block;
  align-items: center;
  text-align: right;
  padding-right: 10px;
  position: relative;
  top: 3px;
}

.user-image {
  display: inline-block;
  align-items: center;
  position: relative;
  top: 5px;
}

img._avatar {
  vertical-align: text-bottom;
}

.order-lastalign {
  text-align: right;
}

.sqcn-admin-button {
  background: #0076a8;
  border: none;
  border-radius: 24px;
  color: #fff !important;
  font-weight: 500;
  padding: 10px 15px;
  position: relative;
  top: 20px;
  text-decoration: none;
  letter-spacing: 0.02em;
}
.sqcn-ad {
  text-align: right;
}

@media only screen and (max-device-width: 980px) {
  .text-hide {
    display: none;
  }

  .user-name {
    font-size: 12px;
  }

  .role-name {
    font-size: 12px;
  }

  .content {
    top: 0px;
  }

  ._logo {
    position: relative;
    top: 10px;
    left: 0px;
    width: 100%;
  }

  .header {
    height: 78px;
  }
}