.location-change {
  padding: 8px 20px;
  border: 2px solid #0076a8;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 700;
  color: #0076a8;
  background: #ffffff;
  margin-bottom: 0px;
}

.location-banner {
  background: #e6f1f6;
  padding: 20px 16px;
  border-radius: 16px;
  margin-bottom: 24px;
}

.location-banner h4 {
  display: inline-block;
  font-size: 20px;
  color: #0076a8;
  margin-bottom: 0;
}

.location-banner select {
  float: right;
}

.location-drop-icon {
  width: 14px;
  margin-left: 5px;
  vertical-align: baseline;
}

/* location list scrollbar  */

.location-list::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.location-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
.location-list::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}

/* Handle on hover */
.location-list::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

/* location list scrollbar styles ends here */

.location-list-block {
  display: none;
  position: absolute;
  right: 0;
  width: 350px;
  background: #ffffff;
  padding: 10px;
  border: 1px solid #d4d4d4;
  border-radius: 20px;
  line-height: 24px;
  max-width: 350px;
  overflow: auto;
}

.location-list {
  padding: 10px 10px 0;
  max-height: 270px;
  overflow: auto;
  cursor: pointer;
  margin-bottom: 0;
  list-style-type: none;
}

.location {
  margin-bottom: 15px;
}

.location div {
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.location-dropdown {
  position: relative;
  float: right;
}

.location-dropdown:hover .location-list-block {
  display: block;
}

.location-icon {
  margin-right: 7px;
  position: relative;
  top: -2px;
}

.mange-column {
  padding-right: 0px !important;
  text-align: right;
}

.location-column {
  padding-left: 0px !important;
}