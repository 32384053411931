.practice-banner {
  padding: 16px 24px;
  gap: 8px;
  border: 0.5px solid #cce4ee;
  border-radius: 20px;
  box-shadow: 0px 2px 4px rgb(0, 0, 0, 0.1);
  margin: 30px 0px 20px;
  background-image: url("practice-banner.png");
  background-color: #e6f1f6;
  background-size: cover;
}
.practice-name {
  font-weight: 500;
  font-size: 24px;
  line-height: 32px;
  color: #0076a8;
  letter-spacing: 0.02em;
}
.location-count {
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
  color: #001822;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}
.dropdown-toggle {
  height: 40px !important;
  padding: 0px 16px !important;
  gap: 6px !important;
  border: 2px solid #0076a8 !important;
  border-radius: 20px !important;
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 28px !important;
  color: #0076a8 !important;
  letter-spacing: 0.02em !important;
  width: 100%;
}
.btn-success {
  background-color: #fff !important;
}
.dropdown-menu {
  background: #ffffff;
  border: 1px solid #d4d4d4 !important;
  box-shadow: 0px 10px 15px -3px rgb(16 24 40 / 10%),
    0px 4px 6px -4px rgb(16 24 40 / 10%) !important;
  border-radius: 20px !important;
  top: 5px !important;
}
.dropdown-item {
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  letter-spacing: 0.02em !important;
  color: #000 !important;
}
.practice-change {
  padding: 8px 8px;
  border: 2px solid #0076a8;
  border-radius: 30px;
  font-size: 14px;
  font-weight: 700;
  color: #0076a8;
  background: #ffffff;
  margin-bottom: 10px;
}

/* location list scrollbar  */

.practice-list::-webkit-scrollbar {
  width: 12px;
}

/* Track */
.practice-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
.practice-list::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}

/* Handle on hover */
.practice-list::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

/* location list scrollbar styles ends here */

.practice-list-block {
  display: none;
  position: absolute;
  right: 0;
  width: 350px;
  background: #ffffff;
  padding: 10px;
  border: 1px solid #d4d4d4;
  border-radius: 20px;
  line-height: 24px;
  max-width: 350px;
  overflow: auto;
  z-index: 1;
}

.practice-list {
  padding: 10px 10px 0;
  max-height: 330px;
  overflow: auto;
  cursor: pointer;
  margin-bottom: 0;
  list-style-type: none;
  position: relative;
  z-index: 1;
}
.practice button {
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.practice-dropdown {
  position: relative;
  float: right;
}

.practice-drop-icon {
  width: 14px;
  margin-left: 5px;
  vertical-align: baseline;
}

.practice-dropdown:hover .practice-list-block {
  display: block;
}

.body-no-scroll {
 overflow: hidden;
 padding-right: 10px;

}
