.mt-40 {
  margin-top: 40px;
}

.provider-actions {
  margin-bottom: 24px;
  display: flex;

  .provider-status {
    ul {
      padding: 0;
      line-height: 41px;
      margin: 0;

      li {
        list-style: none;
        display: inline-block;
        padding-right: 26px;
        letter-spacing: 0.02em;

        img {
          padding-right: 4px;
          margin-top: -2px;
        }
      }
    }
  }

  .provider-button {
    flex: 1 0;
    text-align: right;

    .add-provider {
      background: #0076a8;
      border-radius: 26px;
      border: none;
      color: #ffffff;
      padding: 10px 16px;
      font-size: 14px;

      span {
        margin-right: 11px;
        font-size: 14px;
      }
    }
  }

  .provider-button-location {
    flex: 1 0;
    text-align: right;
    display: flex;
    justify-content: space-between;

    .add-provider {
      background: #0076a8;
      border-radius: 26px;
      border: none;
      color: #ffffff;
      padding: 10px 16px;
      font-size: 14px;

      span {
        margin-right: 11px;
        font-size: 14px;
      }
    }

    .back-button {
      padding: 8px 8px;
      border: 2px solid #0076a8;
      border-radius: 30px;
      font-size: 14px;
      font-weight: 700;
      color: #0076a8;
      background: #ffffff;
      width: 6rem;
    }
  }
}

.provider-list-table {
  table {
    thead {
      tr {
        border-bottom: 0px solid #ffffff;

        th {
          background: #e6f1f6;
          font-weight: 500;
          border: 3px solid #ffffff;
          padding: 9px 15px;
          color: #404040;

          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }

        th.provider_Column {
          width: 20%;
        }

        th.provider_Column1 {
          width: 24%;
        }
      }
    }
  }

  tbody {
    tr {
      background: #fafafa;
      td {
        padding: 20px 5px;
        font-size: 14px;

        a {
          text-decoration: none;
          color: inherit;
        }
      }

      td.search_column {
        padding: 0px 1.2px 0px 1.2px;
      }
    }
  }
}

.manage-location-table {
  table {
    thead {
      tr {
        border-bottom: 8px solid #ffffff;

        th {
          background: #e6f1f6;
          font-weight: 500;
          border: 3px solid #ffffff;
          padding: 12px 10px;
          color: #404040;
          font-size: 14px;

          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  tbody {
    tr {
      background: #fafafa;
      border-bottom: 8px solid #ffffff;

      td {
        padding: 20px 10px;
        font-size: 14px;

        a {
          text-decoration: none;
          color: inherit;
        }
      }
    }
  }
}

.add-provider-modal {
  .modal-dialog {
    max-width: 610px;
  }

  .modal-content {
    padding: 24px;
  }

  .modal-header {
    border-bottom: none;
    padding: 0;
    padding-bottom: 10px;

    .modal-title {
      font-size: 20px;
      line-height: 28px;
    }

    .btn-close {
      color: #262626;
      opacity: 1;
      font-size: 12px;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .modal-body {
    padding: 0;
    margin-bottom: 15px;

    p {
      color: #171717;
      font-weight: 500;
    }

    .no-match {
      color: #92400e;

      img {
        margin-right: 10px;
        margin-top: -3px;
      }
    }

    form {
      .dropdown {
        position: absolute;
        z-index: 2;
      }
    }
  }

  .search-npi {
    label {
      font-size: 14px;
      color: #004765;
      font-weight: 700;
    }

    input {
      border: none;
      border-bottom: 1.5px solid #66adcb;
      border-radius: 0;
      width: 50%;
      padding: 5px 20px 5px 0;
      font-weight: 500;

      &:focus {
        box-shadow: none;
      }
    }

    .search-input {
      background: url("./images/Icon-search.svg") no-repeat right;
    }
  }

  .modal-footer {
    border-top: none;
    background: #e6f1f6;
    border-radius: 16px;
  }
}

.search-input.new {
  background: url("./images/Icon-search.svg") no-repeat 5px center !important;
  border: 1px solid var(--Neutral-300, #d4d4d4) !important;
  padding: 6px 0px 8px 25px !important;
  border-radius: 0 !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  letter-spacing: 0.28px !important;
  color: #171717 !important;
  width: 100% !important;
}

.update-provider-modal {
  .modal-dialog {
    max-width: 610px;
  }

  .modal-content {
    padding: 24px;
  }

  .modal-header {
    border-bottom: none;
    padding: 0;
    padding-bottom: 10px;
    display: block;

    .modal-title {
      font-size: 20px;
      line-height: 28px;
      text-align: center;
      letter-spacing: 0.02em;
    }

    .btn-close {
      color: #262626;
      opacity: 1;
      font-size: 12px;
      position: absolute;
      right: 15px;
      top: 15px;

      &:focus {
        box-shadow: none;
      }
    }
  }

  .modal-body {
    padding: 0;
    margin-bottom: 15px;

    p {
      color: #262626;
      font-weight: 400;
      text-align: center;
      margin-bottom: 5px;
      letter-spacing: 0.02em;
    }
  }

  .modal-footer {
    border-top: none;
    background: #e6f1f6;
    border-radius: 16px;
  }
}

input.nb-input {
  border: none;
  border-radius: 0;
  background: transparent;
  border-bottom: 1px solid #a3a3a3;
  padding: 0;
  padding-top: 5px;
  font-weight: 500;

  &:focus {
    box-shadow: none;
    background: transparent;
    border-color: #66adcb;
  }
}

select.nb-select {
  border: none;
  border-bottom: 1px solid #a3a3a3;
  border-radius: 0;
  background: #fafafa;
  padding: 0;
  padding-top: 1px;
  position: relative;
  background-image: url(./images/dropdownIcon.svg);
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  font-weight: 500;

  // &:after{
  //   content: '';
  //   background: url(dropdownIcon.svg);
  // }
  &:focus {
    box-shadow: none;
    border-color: #66adcb;
  }
}

.location-banner {
  .add-location {
    background: #0076a8;
    border-radius: 26px;
    border: none;
    color: #ffffff;
    padding: 10px 16px;
    font-size: 14px;

    &:hover,
    &:active {
      background-color: #0076a8 !important;
    }

    span {
      margin-right: 11px;
      font-size: 14px;
    }
  }
}

.practice-loc-title {
  line-height: 41px;
}

.white-btn {
  color: #404040;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  border-radius: 20px;
  font-weight: 700;
  font-size: 16px;
  padding: 8px 73px;
}

.blue-btn {
  background: #0076a8;
  border-radius: 20px;
  padding: 8px 46px;
  letter-spacing: 0.02em;
  color: #ffffff;
  font-weight: 700;
  border: 2px solid #0076a8;

  &:disabled {
    opacity: 0.8;
  }
}

.practice-location-title {
  padding: 0px 16px;

  h5 {
    font-size: 20px;
    line-height: 28px;
    font-weight: 500;
    color: #0076a8;
    margin-bottom: 20px;
  }
}

.fg-2 {
  flex-grow: 2 !important;
}

.ta-right {
  text-align: right;
}

.add-location-footer {
  text-align: center;
  margin-top: 24px;

  p {
    color: #005e86;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 8px;
  }

  .add-location-buttons {
    background: #e6f1f6;
    padding: 20px;
    border-radius: 16px;

    button {
      margin: 5px;
    }
  }
}

.no-providers {
  text-align: center;
  color: #92400e;
  background: #fafafa;
  font-weight: 500;
  padding: 12px;
  margin: 0;
  margin-bottom: 15px;

  span {
    img {
      margin-top: -2px;
      margin-right: 5px;
    }
  }
}

.breadcrumb {
  li.breadcrumb-item {
    font-size: 14px;
    color: #404040;
    letter-spacing: 0.02em;
    position: relative;
    padding: 0 15px;

    &.active {
      color: #005e86;
    }

    &:first-child {
      padding-left: 0;
      white-space: nowrap;
      max-width: 680px;
      overflow: hidden;
      text-overflow: ellipsis;

      &::before {
        display: none;
      }
    }

    &:before {
      content: "";
      background: url("./images/Icon-b-divider.svg") no-repeat;
      width: 8px;
      height: 12px;
      position: absolute;
      left: -4px;
      top: 7px;
    }

    a {
      text-decoration: none;
      color: #404040;

      img {
        vertical-align: sub;
      }
    }

    img {
      vertical-align: sub;
      margin-right: 7px;
    }
  }
}

.provider-summary-actions {
  padding-bottom: 28px;

  .text-right {
    text-align: right;
  }

  .edit-provider-button {
    color: #ffffff;
    background: #0076a8;
    border-radius: 24px;
    border: none;
    padding: 8px 66px;
    font-weight: 500;
    text-decoration: none;

    a {
      color: #ffffff;
    }
  }

  .last-updated-time {
    color: #0076a8;
    font-weight: 700;
    position: relative;
    right: 12px;
    font-size: 14px;
    vertical-align: middle;
  }
}

.edit-provider {
  background: #fff;
  border: 1px solid #e1eff5;
  border-radius: 20px;
  padding: 24px;
}

.pagination-wrapper {
  display: flex;

  .provider-pagination {
    display: flex;
    margin-left: auto;
    list-style-type: none;
    padding: 0;
    font-size: 14px;
    color: #0076a8;

    button {
      border: none;
      background: none;
      padding: 1px 16px;

      img {
        vertical-align: initial;
        margin-bottom: -1px;
      }
    }
  }
}

.info {
  .form-check-inline {
    padding-left: 0;
  }

  .form-control {
    &:disabled {
      background: transparent;
    }
  }

  // .form-control:focus-within {
  //   color: #0076A8;
  // }

  input[type="radio"] {
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent !important;
    margin: 0;
    width: 22px !important;
    height: 22px !important;
    border: 1.5px solid #0076a8 !important;
    border-radius: 50% !important;
    transform: translateY(-0.075em);
    display: grid;
    place-content: center;
    margin-right: 10px;
    cursor: pointer;
  }

  input[type="radio"]::before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 1em 1em #0076a8;
    background-color: #0076a8;
  }

  input[type="radio"]:checked::before {
    transform: scale(1);
  }

  input[type="radio"]:focus {
    outline: none;
    outline-offset: unset;
    box-shadow: none;
  }

  .react-datepicker__input-container {
    input {
      border: none;
      border-bottom: 1px solid #a3a3a3;
      padding: 0;
      color: #171717;
      letter-spacing: 0.02em;
      background: url("./images/Icon-date.svg") no-repeat right;
      cursor: pointer;
      width: 100%;

      &:focus-visible {
        outline: none;
      }
    }
  }
}

/* location list scrollbar  */
.dropdown.locations-result::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.dropdown.locations-result::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
.dropdown.locations-result::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}

/* Handle on hover */
.dropdown.locations-result::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

/* location list scrollbar styles ends here */
.dropdown {
  position: absolute;
  background: #ffffff;
  // padding: 10px;
  border: 1px solid #d4d4d4;
  border-radius: 20px;
  line-height: 24px;
  max-width: 350px;
  overflow: auto;
  max-height: 381px;
  cursor: pointer;
  margin-bottom: 0;
  list-style-type: none;
}

.dropdown-npi-search {
  max-width: 35% !important;
}

.nonpi_text {
  color:chocolate;
}

.nonpi_div {
  padding: 10px;
}

.dropdown-row {
  background-color: #fff;
  border: 0;
  text-align: left;
  padding: 5px 10px;
  display: block;
  width: 100%;
  font-size: 14px;
}
.dropdown-row-npi {
  position: sticky;
  bottom: 0px;
  height: 70px;
  // display: inline-flex;
  // justify-content: center;
  background-color: #fff;
  border: 0;
  text-align: center;
  padding: 20px 10px;
  display: block;
  width: 100%;
  font-size: 14px;
}
.cancel-button {
  position: sticky;
  bottom: 0px;
  height: 35px;
  padding: 20px;
  width: 35%;
}

.location-dropdown:hover .location-list-block {
  display: block;
}

.locations-result {
  // padding: 10px 10px 0;
  max-height: 270px;
  overflow: auto;
  cursor: pointer;
  margin-bottom: 0;
}

button[disabled="disabled"],
button:disabled {
  background: #66adcb;
  border-color: #66adcb;
}

input:disabled {
  color: #a3a3a3 !important;
  opacity: 0.3;
}

div:disabled {
  color: #a3a3a3;
}

.title-dropdown {
  position: relative;

  .title-change {
    padding: 5px 0px;
    border: none;
    border-bottom: 1px solid #a3a3a3;
    font-size: 14px;
    color: #171717;
    font-weight: 500;
    letter-spacing: 0.02em;

    span {
      float: right;
    }
  }

  .title-list-block {
    display: none;
    position: absolute;
    right: 0;
    background: #ffffff;
    padding: 10px;
    border: 1px solid #d4d4d4;
    border-radius: 20px;
    line-height: 24px;
    overflow: auto;
    width: 100%;
    z-index: 1;

    .title-list {
      padding: 10px 10px 0;
      max-height: 195px;
      overflow: auto;
      cursor: pointer;
      margin-bottom: 0;
      list-style-type: none;

      .titles {
        margin-bottom: 6px;

        button {
          background: none;
          border: none;
          text-align: left;
          cursor: pointer;
        }
      }
    }
  }

  &:hover {
    .title-list-block {
      display: block;
    }

    .title-change {
      span {
        img {
          transform: rotate(-180deg);
        }
      }
    }
  }
}

.selected-primary,
.selected-alternate {
  color: #0076a8;
  font-weight: 500;
}

.providers-result {
  max-height: 171px;
  overflow: auto;
}

/* title list scrollbar  */

.title-list::-webkit-scrollbar,
.providers-result::-webkit-scrollbar {
  width: 5px;
}

/* Track */
.title-list::-webkit-scrollbar-track,
.providers-result::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
.title-list::-webkit-scrollbar-thumb,
.providers-result::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}

/* Handle on hover */
.title-list::-webkit-scrollbar-thumb:hover,
.providers-result::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

/* title list scrollbar styles ends here */
.provider-search-list {
  background: #fafafa;
  margin-bottom: 10px;
  font-size: 14px;

  a {
    padding: 13px 21px;
    display: block;
    text-decoration: none;
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  .provider-result-email {
    word-wrap: break-word;
  }
}

.npi-search-list {
  background: #fafafa;
  font-size: 14px;
  padding: 10px;
  margin: 0px;

  a {
    padding: 13px 21px;
    display: block;
    text-decoration: none;
    color: inherit;

    &:hover {
      color: inherit;
    }
  }

  .provider-result-email {
    word-wrap: break-word;
  }
}

.updated-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #14532d;
  margin-bottom: 0;
}

.updated-icon {
  position: relative;
  top: -2px;
  margin-right: 7px;
}

.alert.alert-success {
  padding: 12px 20px !important;
  background: #f0fdf4 !important;
  mix-blend-mode: normal !important;
  border: 1px solid #bbf7d0 !important;
  border-radius: 6px !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  letter-spacing: 0.02em !important;
  color: #166534 !important;
}

.close-icon-button {
  float: right;
  border: 0 !important;
  background: #f0fdf4 !important;
  padding: 0 !important;
  position: relative;
  right: -3px;
}

.edit-form {
  .form-label {
    color: #004765;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: 0.02em;
    margin-bottom: 0;
  }

  input.nb-input {
    padding-top: 1px;
  }
}

.form-error {
  font-size: 12px;
  color: red;
}

.error-field {
  border-bottom: 1px solid red !important;
}

.title.disabled {
  font-weight: 700 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  align-items: center !important;
  letter-spacing: 0.02em !important;
  color: #a3a3a3 !important;
}

.error-icon {
  margin-right: 5px;
  position: relative;
  top: -2px;
}

.alert-success {
  .close-icon-button {
    background: none !important;
  }
}

.dropdown:empty {
  border: none;
  background-color: #fafafa;
}

.statusText {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #14532d;
}

.Error-message {
  font-size: 12px !important;
  color: red !important;
  font-weight: 400 !important;
}

.locationtypeicon {
  position: relative;
  top: -20px;
}

.checkhead {
  text-align: center;
}

.bulk-update-box {
  justify-content: flex-end;
  align-items: center;
  padding: 20px;
  gap: 12px;
  width: 100%;
  height: 88px;
  background: #e6f1f6;
}

.white-btn-update {
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  width: 155px;
  height: 48px;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  border-radius: 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  color: #404040;
  margin-right: 8px;
}

.blue-btn-update {
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  gap: 8px;
  width: 155px;
  height: 48px;
  background: #0076a8;
  border: 2px solid #0076a8;
  border-radius: 24px;
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 24px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
  color: #ffffff;
}

.update-button {
  text-align: right;
}

.update-text {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  letter-spacing: 0.02em;
  color: #005e86;
  text-align: right;
  position: relative;
  top: 12px;
  margin-bottom: 0;
}

.bulk-update-box.false {
  display: none;
}

.tooltip > div.tooltip-inner {
  background-color: #171717 !important;
  color: #fff !important;
  border-radius: 24px;
  justify-content: center;
  align-items: center;
  padding: 4px 12px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  align-items: center;
  text-align: center;
  letter-spacing: 0.02em;
}

.form-check-input:checked {
  background-color: #0076a8 !important;
  border-color: #0076a8 !important;
}

.form-check-input:focus {
  box-shadow: none !important;
}

.form-check-input {
  border: 1.25px solid #d4d4d4 !important;
  border-radius: 3.33333px !important;
  width: 20px !important;
  height: 20px !important;
}

.dropbtn {
  background: #04aa6d;
  border: none;
  font-size: 17px;
  padding: 17px;
  color: #fff;
}

.dropdown1 {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  z-index: 1;
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.2);
  padding: 8px 0px;
  min-width: 260px;
  height: 112px;
  background: #ffffff;
  border-radius: 8px;
  left: -16px;
  top: 32px;
}

.dropdown-content::after {
  content: "";
  position: absolute;
  bottom: 100%;
  left: 9.5%;
  margin-left: -5px;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
}

.dropdown-content button {
  display: block;
  color: #404040;
  text-decoration: none;
  padding: 12px 10px;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  background-color: #fff;
  border: 0 !important;
  width: 260px;
  height: 48px;
  text-align: left;
  letter-spacing: 0.02em;
}

.dropdown-content button:hover {
  background: #3391b9;
  color: #fff;
}

.dropdown1:hover .dropdown-content {
  display: block;
}

.dropdown1:hover .dropbtn {
  background: #3e8341;
}

.phone-head5 {
  width: 15%;
}

.phone-head4 {
  width: 9%;
}

.phone-head6 {
  width: 14%;
}

.location-banner.provider-loc {
  margin-bottom: 10px;
}

.provider-location-table {
  table {
    thead {
      tr {
        border-bottom: 8px solid #ffffff;

        th {
          background: #e6f1f6;
          font-weight: 500;
          border: 3px solid #ffffff;
          padding: 12px 10px;
          color: #404040;
          font-size: 14px;

          &:first-child {
            border-left: 0;
          }

          &:last-child {
            border-right: 0;
          }
        }
      }
    }
  }

  tbody {
    tr {
      background: #fafafa;
      border-bottom: 8px solid #ffffff;

      td {
        padding: 20px 10px;
        font-size: 14px;
        font-weight: 500;
        color: #404040;
        vertical-align: middle;

        a {
          text-decoration: none;
          color: inherit;
        }
      }

      td.search_column {
        padding: 0px 3px 0px 0px;
      }

      td.search_column1 {
        padding: 0px 0px 0px 0px;
      }

      td.Primary1 {
        background-color: #e5e5e5;
        border-bottom: 1px solid #e5e5e5;
        word-break: break-all;
      }

      td.Secondary1 {
        background-color: #f5f5f5;
        border-bottom: 1px solid #e5e5e5;
        word-break: break-all;
      }
    }
  }
}

.location-table-list {
  max-height: 270.5px;
  overflow-y: auto;
  display: table-caption;
}

/* location list scrollbar  */
.location-table-list::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.location-table-list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px transparent;
  border-radius: 10px;
}

/* Handle */
.location-table-list::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 10px;
}

/* Handle on hover */
.location-table-list::-webkit-scrollbar-thumb:hover {
  background: #d9d9d9;
}

.search_column.new {
  padding: 0px 1px 0px 0px !important;
}

.search_column.new1 {
  padding: 0px 0px 0px 2px !important;
}

.sort_icon_arrow {
  border: 0 !important;
  background-color: transparent;
  text-align: right;
  float: right;
}

.date_text {
  position: relative;
  top: 3px;
}

.search-npi.loc1 {
  width: 82%;
  display: inline-block;
}

.search-npi.loc2 {
  width: 85.2%;
  display: inline-block;
}

.sort_icon_arrow.pl {
  background-color: #fff !important;
  height: 35px;
  border-radius: 0px;
  position: relative;
  top: 0.5px;
  left: -1px;
  opacity: 0.5 !important;
  border: 1px solid #a3a3a3 !important;
}
.sort_icon_arrow.pl2 {
  background: none;
  position: static;
  transform: none;
}
.provider_wrapper {
  .search-npi.loc1,
  .search-npi.loc2 {
    width: 100%;
  }
  .sort_icon_arrow.pl {
    position: absolute;
    border: none !important;
    width: 35px;
    top: 34px;
    opacity: 1 !important;
    background: none !important;
    float: right;
    right: 15px;
    left: auto;
  }
  .search-input.new {
    padding: 0.75rem 1.5rem 0.75rem 1.7rem !important;
  }
}
.mail_text {
  color: #0076a8;
  text-decoration: none;
  font-weight: 500;
}

.npi_validation_icon {
  padding-left: 1rem;
  vertical-align: inherit !important;
}

.cursor_pointer {
  z-index: 1000;
}