.Approvalbox {
  border: 1px solid #d4d4d4;
  border-radius: 25px;
  padding: 10px;
  margin-bottom: 24px;
}

.mainblockbox {
  background: #fafafa;
  border-radius: 16px;
  padding: 20px;
}
.pending {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #df6400 !important;
}
.submit-box {
  background: #e6f1f6;
  border-radius: 16px;
  padding: 20px 0px;
  text-align: center;
}
.Reject-button {
  background: #ffffff;
  border: 2px solid #e5e5e5;
  border-radius: 24px;
  padding: 12px 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #404040;
  margin-right: 12px;
}

.Cancel-button {
  background: #ffffff;
  border: 2px solid #e5e5e5;
  border-radius: 24px;
  padding: 12px 24px;
  font-weight: 700;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  letter-spacing: 0.02em;
  color: #404040;
  margin-right: 12px;
  margin-bottom: 24px;
}
button[disabled=disabled], button:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}
.location-name-box {
  background: #e6f1f6;
  border-radius: 16px;
  padding: 10px 20px;
  margin-bottom: 0.5rem;
}
.location-name-box h6 {
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  letter-spacing: 0.02em;
  color: #0076a8;
  margin-bottom: 0;
  display: inline-block;
  margin-left: 15px;
}
.status-pending {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #df6400;
  text-align: right;
  margin-bottom: 0;
}
.status-approve {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #498500;
  text-align: right;
  margin-bottom: 0;
}
.status-rejected {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.02em;
  color: #B45309;
  text-align: right;
  margin-bottom: 0;
}
.info-text {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #0076a8;
  margin-bottom: 0.5rem;
  position: relative;
  left: 20px;
}
.checkhead {
  text-align: center;
}
