.admin-header {
  background-color: #fff;
  height: 90px;
}
button.user-signout1 {
  border: 0;
  background: #fff;
  position: relative;
  top: -3px;
}
.pro-sidebar {
  width: 230px !important;
  min-width: 230px !important;
}
.pro-menu ul li {
  margin: 5px 0px !important;
}

.logo-text1 {
  font-family: "Red Hat Display", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  color: #171717;
  letter-spacing: 0.02em;
  top: 12px;
  position: relative;
  left: 5px;
  display: inline-block;
}
.user-name1 {
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  color: #000;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}
.user-signout1 {
  font-weight: 700;
  font-size: 12px;
  line-height: 20px;
  color: #0076a8;
  letter-spacing: 0.02em;
  cursor: pointer;
}
.role-name1 {
  font-weight: 500;
  font-size: 12px;
  line-height: 20px;
  color: #000;
  letter-spacing: 0.02em;
  margin-bottom: 0;
}
.user-content1 {
  display: inline-block;
  align-items: center;
  text-align: right;
  padding-right: 10px;
  position: relative;
}
.user-image1 {
  display: inline-block;
  align-items: center;
  position: relative;
  top: -5px;
}
img._avatar {
  vertical-align: text-bottom;
}
.order-lastalign {
  text-align: right;
}
.text-decoration-none {
  text-decoration: none !important;
  font-size: 24px !important;
  font-weight: 500 !important;
  line-height: 32px !important;
  letter-spacing: 0.02em !important;
}
.sc-hknOHE .head-div .icon-suffix {
  cursor: pointer;
  position: relative !important;
  top: 4px !important;
  left: 5px !important;
}
.sc-hknOHE .head-div {
  padding: 28.5px 20px !important;
}
.fmwcVR {
  background: rgb(0, 94, 134);
  color: rgb(255, 255, 255);
  height: 100%;
  width: 230px !important;
  min-width: 230px !important;
}
.fmwcVR.toggled {
  width: 80px !important;
  min-width: 80px !important;
}
.sc-hknOHE .head-div.toggled {
  padding: 32px 20px !important;
}
.sc-hCPjZK a.active > li {
  border-left: 3px solid #fff !important;
}
.hQSxZz {
  list-style: none;
  margin: 5px 0px !important;
}
.gmeTLe .item-content {
  font-size: 14px !important;
  letter-spacing: 0.02em;
}
.bSzxEe.toggled {
  width: 80px !important;
  min-width: 80px !important;
}
@media only screen and (max-device-width: 980px) {
  .text-hide {
    display: none;
  }
  .user-name {
    font-size: 12px;
  }
  .role-name {
    font-size: 12px;
  }
  .content {
    top: 0px;
  }
  ._logo {
    position: relative;
    top: 10px;
    left: 0px;
    width: 100%;
  }
  .header {
    height: 78px;
  }
}
